<template>
  <div class="order__wrapper">
    <page-header
      back-button
      @back-button-click="$router.push({ name: 'view-products' })"
    >
      Список корзин для заказа
      <template #subtitle>
        Выберите корзину или несколько корзин, на основе которых будет создан заказ
      </template>
      <template #rightArea>
        <el-tooltip
          effect="dark"
          content="Показывать изображения товаров"
        >
          <el-button
            class="breadcrumb-container__button"
            size="mini"
            icon="el-icon-picture"
            :type="displayCatalogImages ? 'primary': 'default'"
            @click="SET_DISPLAY_CATALOG_IMAGES(!displayCatalogImages)"
          />
        </el-tooltip>
      </template>
    </page-header>

    <div
      v-if="baskets.length && !isBasketsLoading"
      class="order__wrapper-row"
    >
      <OrderCreateBasketBlock
        v-for="(basket, basketIndex) in baskets"
        :key="basket.id"
        v-model="multipleSelection"
        :products-without-active="productsWithoutActive"
        :product-with-error="productWithError"
        :products-with-errors="productsWithErrors"
        :products-over-stock="productsOverStock"
        :basket="basket"
        :is-open="
          (multipleSelection.indexOf(basket.id) !== -1 && multipleSelection.length === 1)
            || (multipleSelection.length === 0 && basketIndex === 0)
        "
        @updateSum="updateSum"
        @changeQuantity="changedBasketQuantity"
      />

      <div
        v-if="activeBasketId && recommendations.length"
        class="recommendations"
      >
        <div class="recommendations-title">
          Может быть интересно
        </div>
        <recommendations-block />
      </div>


      <div class="summary">
        <div
          v-if="!multipleSelection.length"
          class="no-selection-message"
        >
          У вас не выбрано ни одной корзины
        </div>
        <div class="v-step-10 summary__prices">
          {{ productsWithoutActive.length ? 'Сумма заказа без учёта отсутствующих товаров:' : 'Сумма заказа:' }}
          <b style="color: black"><price-output :price="normalizePrice(+multiDataSum)" /></b>
          <div v-if="multipleCurrencies && userCurrency !== 'RU'">
            <b style="color: black">
              <price-output
                :price="normalizePrice(+multiDataSumRub)"
                :show-rub="true"
              />
            </b>
          </div>
        </div>
      </div>
      <div
        v-if="productsWithoutActiveInSelectedBaskets.length"
        class="order__footer-text"
      >
        В ваших выбранных корзинах есть товары недоступные для заказа.<br>
        Они не включены в общую сумму заявки
        и будут сохранены в <router-link
          to="/wishlist"
          style="display: contents; color: #1f75a8"
        >
          лист ожидания
        </router-link>.
      </div>
      <div
        v-if="multipleSelection.length >= 2"
        class="order__footer-text"
      >
        Отмеченные корзины будут объединены в один заказ.
      </div>

      <div class="order__footer">
        <div class="v-step-12">
          <div class="order__footer-row">
            <div class="order__footer-row-title">
              <span class="order__footer-row-label">Комментарий к заказу:</span>
            </div>
            <div class="order__footer-row-content">
              <el-input
                v-model="orderComment"
                :rows="3"
                maxlength="255"
                show-word-limit
                type="textarea"
                placeholder="Укажите комментарий к заказу"
                size="mini"
                class="custom-select"
                resize="none"
              />
            </div>
          </div>

          <div class="order__footer-row">
            <div class="order__footer-row-title">
              <span class="order__footer-row-label">Выберите юр.лицо:</span>
            </div>
            <div class="order__footer-row-content">
              <el-select
                v-model="selectedOrgId"
                placeholder="Выберите юр.лицо"
                style="width: 100%"
                size="mini"
                class="custom-select"
                no-data-text="Список юр. лиц пуст"
                @change="selectOrgHandler($event)"
              >
                <el-option
                  v-for="option in orgOption"
                  :key="option.value"
                  :label="option.name"
                  :value="option.id"
                />
              </el-select>
            </div>
          </div>

          <div
            v-if="basketsWithRegionalWarehouse.length === 0"
            class="order__footer-row"
          >
            <div class="order__footer-row-title">
              <span class="order__footer-row-label">Доставка:</span>
            </div>
            <div class="order__footer-row-content">
              <el-button-group>
                <el-button
                  :type="deliveryType === deliveryTypes.pickup ? 'primary' : 'info'"
                  size="small"
                  plain
                  @click="setDeliveryType(deliveryTypes.pickup)"
                >
                  {{ deliveryTypes.pickup }}
                </el-button>
                <el-button
                  :type="deliveryType === deliveryTypes.delivery ? 'primary' : 'info'"
                  size="small"
                  plain
                  @click="setDeliveryType(deliveryTypes.delivery)"
                >
                  {{ deliveryTypes.delivery }}
                </el-button>
              </el-button-group>
            </div>
          </div>

          <div
            v-if="deliveryType === deliveryTypes.delivery && hasFastDeliveryProducts"
            class="order__footer-row"
          >
            <div class="order__footer-row-title" />
            <div class="order__footer-row-content">
              <el-popover
                placement="top"
                trigger="hover"
              >
                Товары доступные для «Быстрой доставки» будут выделены в отдельный заказ<br>и доставлены раньше остальных
                <template #reference>
                  <el-checkbox
                    v-model="fastDelivery"
                    label="Доставить часть заказа быстрее"
                    size="small"
                    border
                    fill="#f0f9eb"
                  />
                </template>
              </el-popover>
            </div>
          </div>

          <div
            v-if="deliveryType === deliveryTypes.delivery"
            class="order__footer-row"
          >
            <div class="order__footer-row-title">
              <span class="order__footer-row-label">Выберите регион доставки:</span>
            </div>
            <div class="order__footer-row-content">
              <el-select
                v-model="selectedCity"
                placeholder="Выберите регион"
                :loading="citiesLoading"
                value-key="cityId"
                size="mini"
                class="custom-select"
                style="width: 100%"
              >
                <el-option
                  v-for="city in cities"
                  :key="'city_' + city.cityId"
                  :label="city.name"
                  :value="city"
                />
              </el-select>
            </div>
          </div>
          <div
            v-if="deliveryType === deliveryTypes.delivery"
            class="order__footer-row"
          >
            <div class="order__footer-row-title">
              <span class="order__footer-row-label">Выберите адрес доставки:</span>
            </div>
            <div class="order__footer-row-content">
              <div
                v-if="selectedOrgId && !disabledAddress && addressesOption.length === 0"
                class="informer-icon"
              >
                <el-popover
                  placement="top"
                  trigger="hover"
                  width="340"
                  content="Свяжитесь со своим менеджером и сообщите ему актуальный адрес доставки"
                >
                  <template #reference>
                    <i class="el-icon-warning-outline" />
                  </template>
                </el-popover>
              </div>
              <el-select
                ref="selectAddress"
                v-model="selectedAddress"
                :disabled="disabledAddress"
                style="width: 100%"
                placeholder="Выберите адрес доставки"
                no-data-text="Нет адреса"
                size="mini"
                class="custom-select"
              >
                <el-option
                  v-for="option in addressesOption"
                  :key="option.value"
                  :label="option.address"
                  :value="option.address"
                />
              </el-select>
            </div>
          </div>
          <!--          <div class="order__footer-row" v-if="deliveryType === deliveryTypes.delivery">-->
          <!--            <div class="order__footer-row-title">-->
          <!--              <span class="order__footer-row-label">Выберите дату доставки:</span>-->
          <!--            </div>-->
          <!--            <div class="order__footer-row-content">-->
          <!--              <el-select-->
          <!--                v-model="selectedDate"-->
          <!--                style="width: 100%"-->
          <!--                placeholder="Выберите дату доставки"-->
          <!--                no-data-text="Нет доступных дат"-->
          <!--                ref="selectAddress"-->
          <!--                size="mini"-->
          <!--                class="custom-select"-->
          <!--              >-->
          <!--                <el-option-->
          <!--                  v-for="option in deliveryDatesList"-->
          <!--                  :key="option.deliveryDate.toISOString().substring(0, 10)"-->
          <!--                  :label="formatDate(option.deliveryDate)"-->
          <!--                  :value="option.stockDate.toISOString().substring(0, 10)"-->
          <!--                />-->
          <!--              </el-select>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <el-alert
          v-if="multiDataSum < minimumOrderSum"
          :title="'Заказ от ' +minimumOrderSum+' ' + userCurrencySign + '. Добавьте товары ещё на ' + (minimumOrderSum - multiDataSum).toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' + userCurrencySign"
          type="warning"
          :closable="false"
          style="margin-bottom: 10px"
        />
        <div
          v-if="_role !== 'guest'"
          class="order__footer_row_three"
        >
          <el-popover
            ref="popover"
            class="v-step-13 order__button"
            placement="left-start"
            :offset="0"
            width="95px"
            trigger="click"
            :content="contentError"
          >
            <template #reference>
              <el-button
                :type="activeButton ? 'primary' : 'info'"
                size="small"
                :loading="isLoading"
                @click="createOrder"
              >
                Оформить заказ
              </el-button>
            </template>
          </el-popover>
        </div>
        <div v-else>
          Вы не можете оформить заказ, пока находитесь в режиме гостя
        </div>
      </div>
    </div>
    <div
      v-if="!isBasketsLoading && !baskets.length"
      style="margin-top: 50px; font-size: 14px; color: #909399"
    >
      Для оформления заказа добавьте товары в корзину
    </div>
    <div
      v-if="isBasketsLoading"
      v-loading="true"
      style="height: 50px"
    />
  </div>
</template>

<script>
import {
addDays, dayOfWeek,   getDeliveryDaysForDate, regionalWarehouseDeliveryDays, weekDays
} from 'utils/deliveryDays';
import formatDate, { formatDateNumeric } from 'utils/formatDate';
import { noop } from 'utils/functions';
import { getPrice, normalizePrice } from 'utils/normalizePrice';
import supplierCodeName from 'utils/supplierCodeName';
import ymMixin from 'utils/ymMixin';
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';

import PageHeader from '@/components/page-header/page-header';
import RecommendationsBlock from "@/components/recommendations.vue";
import deliveryTypes from '@/enums/deliveryTypes';
import createOrderCheckErrors from '@/views/order/utils/createOrderCheckErrors';

import OrderCreateBasketBlock from './components/orderCreateBasketBlock';
import orderTour from './orderTour';

export default {
  name: 'OrderCreate',
  components: {RecommendationsBlock, PageHeader, OrderCreateBasketBlock },
  mixins: [ymMixin, orderTour],
  metaInfo: {
    title: 'Оформление заказа'
  },
  data() {
    return {
      selectedOrgId: '',
      selectedAddress: '',
      selectedDate: null,
      // Товар, по которым пришла ошибка при попытке оформить заказ
      productWithError: null,
      // Товары, по которым пришла ошибка при попытке оформить заказ
      productsWithErrors: [],
      productsOverStock: [],
      orderComment: '',
      disabledAddress: true,
      /** @type {number[]} */
      multipleSelection: [],
      contentError: '',
      multiDataSum: 0,
      multiDataSumRub: 0, // Для отображения в разных валютах
      isLoading: false,
      isBasketsLoading: false,
      citiesLoading: false,
      deliveryTypes,
      deliveryType: localStorage.getItem('deliveryType') || deliveryTypes.delivery,
      fastDelivery: false
    };
  },
  computed: {
    ...mapGetters('auth', ['_role', '_warehouseId', '_roleAdmin']),
    ...mapGetters('products', ['minimumOrderSum', 'suppliersNames']),
    ...mapGetters('ui', ['userCurrencySign']),
    ...mapState('ui', ['multipleCurrencies', 'userCurrency', 'displayCatalogImages']),
    ...mapState('basket', ['multiBasket', 'stockbalance', 'activeBasketId']),
    ...mapState('products', ['suppliersNames']),
    ...mapState('recommendations', ['recommendations']),
    ...mapState('accounts', {
      orgOption: 'org',
      addressesOption: 'addresses',
      deliveryDates: 'deliveryDates',
      city: 'city',
      cities: 'cities',
      accountId: 'accountId',
    }),
    baskets() {
      return this.multiBasket.filter(item => item.basketItems && item.basketItems.length);
    },
    activeButton() {
      return this.multipleSelection.length
        && this.selectedOrgId
        && this.selectedAddress
        && this.multiDataSum > this.minimumOrderSum;
    },
    /** @type {number[]} */
    productsWithoutActive() {
      return [
        ...this.stockbalance.productsWithoutActive,
        ...this.stockbalance.productsWithoutPrice,
      ]
        .map(item => item.productId)
        .filter(productId => !this.productsOverStock.includes(productId));
    },
    productsWithoutActiveInSelectedBaskets() {
      const filterActiveBasket = basket => this.multipleSelection.includes(basket.id);
      const filterBasketHasInactiveItem = basketItem => this.productsWithoutActive.includes(basketItem.productId);

      return this.multiBasket
        .filter(filterActiveBasket)
        .map(
          basket => basket.basketItems
            .filter(filterBasketHasInactiveItem)
        ).flat();
    },
    basketsWithRegionalWarehouse() {
      const filterIsRegionalWarehouse = basketItem => basketItem.warehouseId && basketItem.warehouseId !== this._warehouseId;
      const filterActiveBasket = basket => this.multipleSelection.includes(basket.id);

      return this.multiBasket
        .filter(filterActiveBasket)
        .filter(
          basket => basket.basketItems
            .filter(filterIsRegionalWarehouse).length
        );
    },
    /**
     *
     * @return {{stockDate: Date, deliveryDate: Date}[]}
     */
    deliveryDatesList() {
      const list = [];

      const filterActiveBasket = basket => this.multipleSelection.includes(basket.id);

      let supplierMaxDeliveryDays = 0;
      this.multiBasket
        .filter(filterActiveBasket)
        .forEach((basket) => {
          basket.basketItems.forEach((item) => {
            const deliveryDays = item.supplier ? JSON.parse(item.supplier).stocks.deliveryDays : 0;
            if (deliveryDays > supplierMaxDeliveryDays) {
              supplierMaxDeliveryDays = deliveryDays;
            }
          });
        });

      for (let dayCount = 0; dayCount < 42; dayCount++) {
        const date = addDays(new Date(), dayCount);
        let stockDate = date;

        if (this.basketsWithRegionalWarehouse.length) {
          stockDate = addDays(stockDate, regionalWarehouseDeliveryDays);
        }
        if (supplierMaxDeliveryDays) {
          stockDate = addDays(stockDate, supplierMaxDeliveryDays);
        }

        const deliveryDaysForDate = getDeliveryDaysForDate(stockDate);
        const deliveryDate = addDays(stockDate, deliveryDaysForDate);

        if (import.meta.env.DEV && this.deliveryDates && this.multiBasket.length) {
          console.groupCollapsed(
            `${formatDateNumeric(date)} / доставка ${deliveryDaysForDate !== null ? formatDateNumeric(deliveryDate) : '-'}`
          );

          if (this.basketsWithRegionalWarehouse.length) {
            console.log(`+${
              regionalWarehouseDeliveryDays} В корзине есть товары у которых склад отличается от склада пользователя`);
          } else {
            console.log('+0: Склад у всех товаров тот же, что и у пользователя');
          }
          console.log(`+${supplierMaxDeliveryDays}: Максимальное количество дней доставки указанное для товаров поставщиков в корзине`);

          console.log(`Дата отгрузки: ${formatDateNumeric(stockDate)} (${weekDays[stockDate.getDay()]})`);
          if (deliveryDaysForDate !== null) {
            console.log(`Количество дней доставки указанное для дня отгрузки: ${deliveryDaysForDate}`);
            console.log(`Доставка (дата отгр+дней доставки): ${formatDateNumeric(deliveryDate)}`);
          } else {
            console.log(`В этот день (${dayOfWeek[stockDate.getDay()]}) отгрузка недоступна, пропускаем`);
          }
          console.groupEnd();
        }
        if (deliveryDaysForDate !== null) {
          list.push({
            stockDate,
            deliveryDate
          });
        }
      }
      return list;
    },
    /** @type {City|object|null} */
    selectedCity: {
      /**
       * @param {City} city
       * @return {Promise<void>}
       */
      async set(city) {
        await this.SetCity(city.cityId);
        await this.SET_CITY(city);
        this.selectedDate = null;
        await this.getDeliveryDates()
          .catch((err) => {
            this.$alert(err, 'Не удалось получить данные о датах доставки');
          });
      },
      get() {
        return this.city;
      }
    },
    hasFastDeliveryProducts() {
      const filterActiveBasket = basket => this.multipleSelection.includes(basket.id);

      return this.multiBasket
        .filter(filterActiveBasket)
        .filter(
          basket => basket.basketItems
            .filter(item => item.isFastDelivery).length
        ).length > 0;
    }
  },
  watch: {
    activeButton(val) {
      if (val) this.contentError = '';
    },
    baskets() {
      const basketsIdsWithItems =
        this.multiBasket
          .filter(basket => basket.basketItems && basket.basketItems.length)
          .map(basket => basket.id);

      if (!basketsIdsWithItems.length) {
        return;
      }

      // Remove from selection baskets without items
      this.multipleSelection =
        this.multipleSelection.filter(selectionId => basketsIdsWithItems.includes(selectionId))

      // Select the first basket if no selection and make it active
      if (!this.multipleSelection.length) {
        const firstBasketId = basketsIdsWithItems[0];

        if (basketsIdsWithItems.includes(this.activeBasketId)) {
          this.multipleSelection.push(this.activeBasketId);
        } else {
          this.multipleSelection.push(firstBasketId);
          this.SET_ACTIVE_BASKET(firstBasketId)
        }
      }

      this.updateSum();
    }
  },
  async mounted() {
    const GetNewAddress = ({ addressesOption }) => {
      const addressInclude = lastAddress => addressItem => addressItem.address === lastAddress;
      const lastSelectedAddress = localStorage.getItem('selectedAddress') || '';
      return addressesOption.length === 1 ? addressesOption[0].address // Если есть всего один адрес доставки
        : lastSelectedAddress && addressesOption.find(addressInclude(lastSelectedAddress))
          ? lastSelectedAddress // Если предыдущий адрес доставки есть среди текущих
          : '';
    };

    this.isBasketsLoading = true;
    await Promise.all([
      this.GetBasketsWithItems(),
      this.CheckStockbalance(),
    ])
      .then(this.updateSum)
      .then(() => {
        this.getRecommendations()
      })
      .catch((err) => { this.$alert(err.message || err, 'Не удалось загрузить корзины'); });
    this.isBasketsLoading = false;
    await this.GetOrg()
      .then((orgs) => {
        /*
         * Юр. лицо сохраняется в локалсторадж.
         * Проверяем его наличие, если его нет, но юр. лицо всё равно одно, выбираем его;
         * в противном случае, ищем старый id среди полученных с бэка юр. лиц
         */
        const lastSelectedOrgId = localStorage.getItem('selectedOrgId');

        this.selectedOrgId = orgs.length === 1
          ? orgs[0].id
          : orgs.find(item => +lastSelectedOrgId === item.id)
            ? +lastSelectedOrgId
            : (orgs.length > 0 ? orgs[0].id : null);

        /* На всякий случай обновляем id в localStorage и вызываем метод, для выбора доставки */
        if (this.selectedOrgId) {
          localStorage.setItem('selectedOrgId', this.selectedOrgId);
        }
      })
      .catch((err) => {
        this.$message.error('Не удалось загрузить юр. лица: ' + err.message || err);
        throw err
      });

    this.GetAddresses(this.accountId)
      .then(() => {
        this.$nextTick(() => {
          this.disabledAddress = false;
          if (this.$refs.selectAddress) {
            this.$refs.selectAddress.focus();
          }
        });
        this.selectedAddress = GetNewAddress(this);
        if (this.selectedAddress) localStorage.setItem('selectedAddress', this.selectedAddress);
      })
      .catch((err) => {
        this.$alert(err.message || err, 'Не удалось загрузить адреса');
      });
    this.citiesLoading = true;
    await this.GetCities(this.accountId);
    this.citiesLoading = false;

    this.checkForUnavailableItems();
    await this.GetSuppliersNames();
  },
  methods: {
    ...mapActions(
      'accounts',
      ['GetOrg', 'GetAddresses', 'getDeliveryDates', 'SetCity', 'GetCities']
    ),
    ...mapActions('order', ['PostCreateOrder']),
    ...mapActions('basket', [
      'GetProductsBasket',
      'GetBasketsWithItems',
      'CheckStockbalance',
      'StockbalanceFromBaskets',
      'checkDeliveryDate',
      'DeleteProductInBasket',
      'DeleteProductInMultiBasket',
      'PutChangeQuantityProduct',
      'GetMultiBasket',
    ]),
    ...mapActions('wishlist', ['addToWishList']),
    ...mapActions('products', ['GetSuppliersNames']),
    ...mapActions('recommendations', ['getRecommendations']),
    ...mapMutations('products', ['RESET_PRODUCTS']),
    ...mapMutations('accounts', ['SET_CITY']),
    ...mapMutations('ui', ['SET_DISPLAY_CATALOG_IMAGES']),
    ...mapMutations('basket', ['SET_MULTI_BASKET_ITEM_QUANTITY', 'SET_MULTI_BASKET_ITEM_STOCK_BALANCE', 'SET_ACTIVE_BASKET']),
    updateSum() {
      const { productsWithoutActive } = this;
      const multipleSelectionIncludeFilter = ({ id }) => this.multipleSelection.includes(id);
      const noBasketItemsFilter = item => item.basketItems.length;
      const checkActive = productId => !productsWithoutActive.includes(productId);
      const basketItemsReducer = priceProperty => (sum, current) => sum + (
        checkActive(current.productId)
          ? +current[priceProperty] * current.quantity
          : 0
      );
      const getSum = priceProperty => (sum, { basketItems = [] }) => (sum + basketItems.reduce(basketItemsReducer(priceProperty), 0));
      this.multiDataSum = this.multiBasket
        .filter(multipleSelectionIncludeFilter)
        .filter(noBasketItemsFilter)
        .reduce(getSum(this.userCurrency !== 'RU' ? 'tengePrice' : 'price'), 0);
      this.multiDataSumRub = this.multiBasket
        .filter(multipleSelectionIncludeFilter)
        .filter(noBasketItemsFilter)
        .reduce(getSum('price'), 0);
    },
    checkForUnavailableItems() {
      const itemsWithOverStockBalance = this.multiBasket
        .filter(item => this.multipleSelection.includes(item.id))
        .map(item => item.basketItems)
        .flat()
        .filter(item => item.stockBalance !== undefined && item.quantity > item.stockBalance);

      this.productsOverStock = itemsWithOverStockBalance.map(product => product.productId || product.supplier);
    },
    changedBasketQuantity(row) {
      this.productWithError = null;
      this.productsWithErrors = this.productsWithErrors.filter(error => error.id !== row.basketItemId);
      this.checkForUnavailableItems();
    },
    async selectOrgHandler() {
      localStorage.setItem('selectedOrgId', this.selectedOrgId);
    },
    async createOrder() {
      // Проверка заполненности базовых полей, типа юрлица и адреса
      this.contentError = createOrderCheckErrors(this);
      if (this.contentError) return;

      // Проверка на то, что у пользователя выбран регион доставки
      if (!this.selectedCity?.cityId) {
        await this.$alert('Не заполнено поле "Регион доставки" в базе данных, обратитесь в тех.поддержку для заполнения информации');
        return;
      }

      // Запрашиваем состояние товаров в корзине, вдруг какие-то пропали из наличия
      this.isLoading = true;
      await this.GetMultiBasket();
      await this.CheckStockbalance();
      this.isLoading = false;

      // Минимальная сумма заказа для поставщика росско
      const rosskoMinimalSum = 300;

      // Товары поставщика росско в выбранных корзинах
      const rosskoProducts = this.multiBasket
        .filter(item => this.multipleSelection.includes(item.id))
        .map(item => item.basketItems)
        .flat()
        .filter(item => item.supplier && supplierCodeName(JSON.parse(item.supplier).supplier) === 'RSK');

      // Сумма товаров в выбранных корзинах от поставщика росско
      const rosskoProductsSum = rosskoProducts.reduce((sum, current) => sum + getPrice(current) * current.quantity, 0);
      if (rosskoProducts.length && rosskoProductsSum < rosskoMinimalSum) {
        await this.$alert(
          `Для оформления заказа со склада "RSK" сумма товаров должна быть больше ${rosskoMinimalSum} руб.,
          осталось добавить товаров на сумму: ${rosskoMinimalSum - rosskoProductsSum}`
        );
        return;
      }

      // Товары в выбранных корзинах, у которых есть остаток на складе, но выбранное пользователем количество выше
      const itemsWithOverStockBalance = this.multiBasket
        .filter(item => this.multipleSelection.includes(item.id))
        .map(item => item.basketItems)
        .flat()
        .filter(item => item.stockBalance > 0 && item.quantity > item.stockBalance);

      // Список товаров с бэкенда, которых нет в наличии
      const notActiveItems = this.multiBasket
        .filter(item => this.multipleSelection.includes(item.id)) // Находим выбранные корзины
        .map(item => item.basketItems)
        .flat()
        .filter(item => this.productsWithoutActive.includes(item.productId)); // Проверяем наличие в списке с ошибками

      // const externalItems = this.multiBasket
      //   .filter(item => this.multipleSelection.includes(item.id)) // Находим выбранные корзины
      //   .map(item => item.basketItems)
      //   .flat()
      //   .filter(item => item.supplier !== null && item.supplier !== undefined);

      // В случае, если у нас есть проблемные товары, спрашиваем пользователя перед продолжением оформления, готов ли он
      // к тому, что его корзина может измениться. Если не согласен, обрываем дальнейшие действия, заказ оста тся неизменным
      if (itemsWithOverStockBalance.length || notActiveItems.length) {

        let canContinue = false;
        await this.$confirm(
          'Отсутствующие товары будут перенесены в лист ожидания<br>Количество товаров с недостаточным остатком будет изменено на доступное<br>Оформить заказ?',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
            type: 'warning',
            dangerouslyUseHTMLString: true
          }
        ).then(async () => {
          canContinue = true;
        }).catch(noop);
        if (!canContinue) {
          return;
        }
      }

      // Начинаем показывать прогресс отправки
      this.isLoading = true;

      // Перемещаем товары, которых нет в наличии, в лист ожидания; Удаляем их после этого из корзины
      await this.StockbalanceFromBaskets(this.multipleSelection)
        .catch(err => this.$alert(err.message || err, 'Товары не добавлены в лист ожидания'));

      // Добавление товаров, которых недостаточно на складе, в лист ожидания
      /*if (itemsWithOverStockBalance.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of itemsWithOverStockBalance) {
          const remainderQuantity = item.quantity - item.stockBalance;
          // eslint-disable-next-line no-await-in-loop
          await this.addToWishList({
            productId: item.productId,
            quantity: remainderQuantity,
            basketName: this.multiBasket.find(item => this.multipleSelection.includes(item.id))?.name || '',
          });
          // eslint-disable-next-line no-await-in-loop
          this.$store.commit('products/SET_PRODUCT_BASKET_QUANTITY', {
            id: item.basketItemId,
            basketId: this.multipleSelection[0],
            quantity: item.stockBalance
          });
        }
      }*/

      // if (externalItems.length && !this._roleAdmin) {
      //   let deletedItems = 0;
      //   for (const item of externalItems) {
      //     if (!this.suppliersNames.includes(JSON.parse(item.supplier)?.supplier)) {
      //       await this.DeleteProductInMultiBasket({basketItemId: item.basketItemId});
      //       deletedItems++;
      //     }
      //   }
      //   if (deletedItems) {
      //     await this.GetProductsBasket();
      //     await this.$alert('Товары сторонних поставщиков были удалены из вашей корзины');
      //   }
      // }

      if (!this.multipleSelection.length) {
        return;
      }

      // Перед отправкой очищаем список проблемных товаров, по которым ошибки придут при попытке оформления заказа
      this.productWithError = null;
      this.productsWithErrors = [];
      // Отправляем данные на сервер
      await this.PostCreateOrder({
        basketId: this.multipleSelection,
        address: this.selectedAddress,
        orgId: this.selectedOrgId,
        reserve: false,
        comment: this.orderComment,
        // shippingDate: this.deliveryType === this.deliveryTypes.delivery ? this.selectedDate : undefined,
        cityId: this.selectedCity?.cityId,
        deliveryType: this.basketsWithRegionalWarehouse > 0 ? deliveryTypes.delivery : this.deliveryType,
        useStockBalanceInsteadOfQuantity: itemsWithOverStockBalance.length > 0 ? true : undefined,
        isFastDelivery: this.fastDelivery
      })
        .then(async (item) => {
          // Успешное оформление
          // Запрашиваем все корзины
          this.GetProductsBasket().then(noop);
          this.RESET_PRODUCTS();
          // показываем сообшение, что заказ оформлен и перенаправляем в историю заказов
          await this.$router.push({ path: '/history' });
          this.$message.success(`Заказ № ${item.orderId} успешно создан!`);
          // Отправляем событие в метрику
          this.reachGoal('checkout');
        })
        .catch((err) => {
          // Ошибка оформления заказа
          // Показываем ошибку
          this.$alert(err.message || err, 'Не удалось создать заказ');
          // Если есть идентификатор товара с ошибкой, то запоминаем, показываем их около товаров потом
          if (err.basketItemId) {
            this.productWithError = err.basketItemId;
          }
          // Если ошибок несколько, тоже запоминаем
          if (err.errors) {
            this.productsWithErrors = err.errors;
            // eslint-disable-next-line no-restricted-syntax
            for (const error of err.errors) {
              if (error.available !== undefined) {
                this.SET_MULTI_BASKET_ITEM_STOCK_BALANCE({
                  id: error.id,
                  stockBalance: error.available
                });
              }
            }
          }
        });

      // Останавливаем прогресс загрузки на кнопке оформления
      this.isLoading = false;
    },
    setDeliveryType(deliveryType) {
      this.deliveryType = deliveryType;
      localStorage.setItem('deliveryType', deliveryType);
    },
    normalizePrice,
    formatDate,
  }
};
</script>

<style lang="sass">
  @keyframes blink
    0%
      opacity: .5
    40%
      opacity: 1
    60%
      opacity: .5
    100%
      opacity: 1

  .productsWithoutActive > *:not(:last-child)
    color: #fb7e7e

  .productWithError > *:not(:last-child)
    color: #fb7e7e
    animation: blink 1s

  .productWithError > .n-table-row__col_quantity
    background-color: #fb7e7e

  .productsOverStock > *:not(:last-child)
    color: #E6A23C
  .productsOverStock .n-input-number__input
    color: #E6A23C

  .fastDelivery:not(.productsWithoutActive):not(.productWithError) .n-table-row__col
    background-color: #f0f9eb
</style>

<style scoped lang="sass">
  .height-30
    height: 30px

  .order
    &__wrapper
      width: 100%
      background-color: #fff
      overflow-y: auto
      padding: 20px 200px
      @media (max-width: 1300px)
        padding: 20px
    &__footer
      display: flex
      flex-direction: column
      margin-top: 15px
      width: max-content
      float: right
      color: #909399
      @media (max-width: 500px)
        width: 100%
      &-text
        display: flex
        justify-content: flex-end
        margin-top: 15px
        font-size: 12px
        margin-right: 15px
        color: #909399
      &_row_three
        display: flex
        justify-content: flex-end
      &-row
        display: flex
        align-items: flex-start
        margin-bottom: 15px
        @media (max-width: 700px)
          flex-flow: column
        @media (max-width: 500px)
          width: 100%
        &-title
          display: flex
          align-items: center
          flex-grow: 1
          padding-right: 15px
          font-size: 12px
          text-align: right
          min-height: 35px
          @media (max-width: 700px)
            min-height: auto
            margin-bottom: 2px
        &-label
          width: 100%
        &-content
          width: 350px
          flex-shrink: 0
          @media (max-width: 500px)
            width: 100%
    &__button
      align-self: flex-end
    &__wrapper-row__table
      border-top: none

  .order-table__footer
    display: flex
    justify-content: flex-end
    &__wrap
      width: 359px
      height: 30px
      border-left: 1px solid #ffffff
      display: flex
    &__total-text
      width: 80px
      border-right: 1px solid #EBEEF5
      font-size: 10px
      color: #909399
      padding: 7px
      text-align: center
    &__total-count
      width: 93px
      border-right: 1px solid #EBEEF5
      border-bottom: 1px solid #EBEEF5
      font-size: 10px
      padding: 7px
      text-align: center
    &__total-sum
      width: 120px
      border-right: 1px solid #EBEEF5
      border-bottom: 1px solid #EBEEF5
      font-size: 10px
      padding: 7px
      text-align: right

  .informer
    position: relative

  .informer-icon
    position: absolute
    top: 0
    left: 100%
    padding: 5px
    color: #F56C6C
  .no-selection-message
    font-size: 12px
    color: #e6a23b
    @media (max-width: 700px)
      text-align: right
  .summary
    display: grid
    align-items: center
    gap: 10px
    grid-template-areas: 'info prices'
    grid-template-columns: auto auto
    @media (max-width: 700px)
      grid-template-columns: auto
      grid-template-areas: 'info' 'prices'
    &__prices
      grid-area: prices
      font-size: 14px
      margin-right: 15px
      color: #909399
      text-align: right
  .recommendations
    display: flex
    flex-direction: column
    gap: 10px
    margin: 30px 0
  .recommendations-title
    font-size: 14px
    font-weight: 700
</style>
