<script>
import {defineComponent} from 'vue'
import { dragscroll } from 'vue-dragscroll'
import {mapActions, mapMutations, mapState} from "vuex";

import AddToCart from "@/components/add-to-cart/addToCart.vue";
import { normalizePrice } from '@/utils/normalizePrice';

export default defineComponent({
  name: "RecommendationsBlock",
  components: {AddToCart},
  directives: {
    dragscroll
  },
  data() {
    return {
      isDown: false,
      startX: 0,
      scrollLeft: 0,
      scrolled: 0,
      isMounted: false,
      urlSrc: import.meta.env.VUE_APP_IMG_URL,
      loadingProductId: null,
    }
  },
  computed: {
    ...mapState('recommendations', ['recommendations', 'loading']),
    ...mapState('basket', ['multiBasket']),
    hasPrev() {
      return this.scrolled > 0
    },
    hasNext() {
      if(!this.isMounted) return false;
      if(this.loading || !this.recommendations.length) return false;
      return (this.scrolled + this.$refs.slider.offsetWidth) < this.$refs.slider.scrollWidth;
    }
  },
  async mounted() {
    this.isMounted = true;

    const slider = this.$refs.slider;
    if (slider) {
      slider.addEventListener('mousedown', (e) => {
        this.isDown = true;
        slider.classList.add('active');
        this.startX = e.pageX - slider.offsetLeft;
        this.scrollLeft = slider.scrollLeft;
        this.scrolled = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        this.isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mouseup', () => {
        this.isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mousemove', (e) => {
        if (!this.isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - this.startX); //scroll-fast
        slider.scrollLeft = this.scrollLeft - walk;
        this.scrolled = slider.scrollLeft;
      });
    }
  },
  methods: {
    ...mapActions('recommendations', ['getRecommendations']),
    ...mapMutations('recommendations', ['SET_RECOMMENDATIONS']),
    ...mapActions('basket', ['AddProductInBasket']),
    normalizePrice,
    async addProduct(product) {
      product.fromRecommendation = true;
      this.loadingProductId = product.id;
      await this.AddProductInBasket(product).catch(error => {
        this.$alert(error, 'Добавление в корзину', {
          confirmButtonText: 'OK',
        });
      })
      this.loadingProductId = null;
    },
    scrollPrev() {
      let scrollPosition = this.$refs.slider.scrollLeft - 380;
      scrollPosition = scrollPosition >= 0 ? scrollPosition : 0;
      this.$refs.slider.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      })
      this.scrolled = scrollPosition;
    },
    scrollNext() {
      this.$refs.slider.scrollTo({
        left: this.$refs.slider.scrollLeft + 380,
        behavior: 'smooth'
      })
    },
  },
})
</script>

<template>
  <div class="recommendations-wrapper">
    <div
      v-if="loading"
      v-loading="true"
      class="loader"
    />
    <div
      v-if="hasPrev"
      class="recommendations-list__nav"
      data-role="prev"
      @click="scrollPrev"
    >
      <i class="el-icon-arrow-left" />
    </div>
    <div
      v-if="!loading && !recommendations.length"
      class="recommendations-empty"
    >
      Нет рекомендуемых товаров к вашей корзине
    </div>
    <div
      ref="slider"
      class="recommendations-list"
      @scroll="(e) => scrolled = e.target.scrollLeft"
    >
      <div
        v-for="product in recommendations"
        :key="product.id"
        class="recommendation-item"
      >
        <el-image
          :src="`${urlSrc + product.externalId}.JPEG`"
          :preview-src-list="[`${urlSrc + product.externalId}.JPEG`]"
          class="recommendation-item__image"
          fit="cover"
          lazy
        >
          <template #error>
            <span />
          </template>
        </el-image>
        <div class="recommendation-item__main">
          <div
            class="recommendation-item__title"
            :title="product.name"
          >
            {{ product.name }}
          </div>
          <div class="recommendation-item__prices">
            <price-output
              class="recommendation-item__price"
              :price="normalizePrice(product.currentPrice)"
              show-rub
            />
            <add-to-cart
              v-model="product.quantity"
              :disabled="product.stockbalance === 0"
              :min="1"
              :max="product.stockBalance"
              :step="1"
              :loading="loadingProductId === product.id"
              @addToCart="addProduct(product)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="hasNext"
      class="recommendations-list__nav"
      data-role="next"
      @click="scrollNext"
    >
      <i class="el-icon-arrow-right" />
    </div>
  </div>
</template>

<style scoped lang="sass">
.loader
  height: 113px
.recommendations-wrapper
  position: relative
  width: 100%
.recommendations-list
  display: flex
  overflow: auto
  gap: 20px
  scrollbar-width: none
  width: 100%
  ::-webkit-scrollbar
    display: none
  &__nav
    background-color: #fff
    border-radius: 50%
    width: 40px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3)
    position: absolute
    top: 50%
    transform: translateY(-50%)
    font-size: 18px
    z-index: 10
    cursor: pointer
    &[data-role="prev"]
      left: -20px
    &[data-role="next"]
      right: -20px

.recommendations-empty
  font-size: 12px
  text-align: center
  color: #4e4e4e
.recommendation-item
  display: grid
  grid-template-columns: auto 1fr
  gap: 20px
  flex-direction: column
  min-width: 350px
  font-size: 12px
  border: 1px solid #EBEEF5
  border-radius: 8px
  padding: 10px
  align-items: flex-start
  &__image
    width: 70px
    height: 70px
    flex-shrink: 0
  &__main
    display: flex
    gap: 10px
    flex-direction: column
    align-items: flex-start
  &__title
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
  &__prices
    display: flex
    flex-direction: column
    gap: 10px
    margin-top: auto
  &__price
    font-size: 13px
</style>
