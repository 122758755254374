<script>

import { mapState } from 'vuex'

export default {
  name: 'Document',
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('documents', ['types', 'statuses']),
    processing() {
      return this.document.status !== 'DONE'
    }
  },
  methods: {
    download() {
      const a = document.createElement("a");
      a.href = `https://687717.selcdn.ru/site-data/web_env/${this.document.userId}/${this.document.type}/${this.document.uuid}`;
      a.target = "_blank";
      a.click()
    },
    formatDate: strDate => new Date(strDate).toLocaleString('ru', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    })
  },
}
</script>

<template>
  <div class="document">
    <div class="document__main">
      <div class="document__title">
        {{ types[document.type]?.name }} <span :class="['document__status', {'document__status_success': !processing, 'document__status_processing': processing}]">{{ statuses[document.status] }}</span>
      </div>
      <div class="document__id">
        № {{document.uuid}}
      </div>
      <div class="document__date">
        Создан {{ formatDate(document.createdAt) }}<template v-if="document.updatedAt">, обновлён {{formatDate(document.updatedAt)}}</template>
      </div>
    </div>
    <el-button
      v-if="!processing"
      icon="el-icon-download"
      size="small"
      @click="download"
    />
  </div>
</template>

<style scoped lang="sass">
  .document
    display: flex
    align-items: center
    gap: 20px
    padding: 10px
    background: white
    border: 1px solid #ebeef5
    box-shadow: 0 9px 22px 0 rgba(0, 0, 50, 0.05)
    border-radius: 8px
    &:before
      content: ""
      display: block
      width: 40px
      height: 40px
      background: url("@/assets/img/document.svg") no-repeat
      background-size: contain
    &__main
      display: flex
      flex-direction: column
      gap: 5px
      flex-grow: 1
    &__title
      font-weight: 700
      display: flex
      align-items: center
    &__id
      color: #4e4e4e
      font-size: 12px
    &__date
      color: #4e4e4e
      font-size: 12px
    &__status
      flex-shrink: 0
      border-radius: 3px
      font-size: 10px
      text-align: center
      font-weight: bold
      padding: 3px 5px
      display: inline-flex
      align-items: center
      justify-content: center
      text-transform: uppercase
      margin-left: 10px
      &_success
        color: #3D8F5F
        border: 1px solid #3D8F5F
      &_processing
        color: #0074F7
        border: 1px solid #0074F7
</style>
